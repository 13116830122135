var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("masthead", {
        attrs: { title: "Account Settings", "show-search": false },
      }),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "container-fluid pt-4 ml-0 pl-0 pr-0" },
            [
              _c(
                "b-tabs",
                {
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "General" } },
                    [
                      _c("ct-table", {
                        attrs: {
                          loaded: _vm.loaded,
                          items: [_vm.account],
                          fields: _vm.ownerFields,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(actions)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName:
                                            "v-b-modal.edit-general-info-modal",
                                          modifiers: {
                                            "edit-general-info-modal": true,
                                          },
                                        },
                                      ],
                                      staticClass: "mr-2",
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(
                                            "generalInfo",
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Edit\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3329351669
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Phones" } },
                    [
                      _c("ct-table", {
                        attrs: {
                          loaded: _vm.loaded,
                          items: _vm.phones,
                          fields: _vm.phoneFields,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(phoneNumber)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.item.phone_number) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(actions)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.edit-phone-modal",
                                          modifiers: {
                                            "edit-phone-modal": true,
                                          },
                                        },
                                      ],
                                      staticClass: "mr-2",
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit("phone", data.item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Edit\n            "
                                      ),
                                    ]
                                  ),
                                  _vm.phones.length > 1
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-danger",
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletePhone(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Delete\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1460072394
                        ),
                      }),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.add-phone-modal",
                              modifiers: { "add-phone-modal": true },
                            },
                          ],
                          class: _vm.$mq !== "sm" ? "mr-2 rounded" : "",
                          attrs: { variant: "primary", size: "$mq" },
                          on: {
                            click: function ($event) {
                              return _vm.create("phone")
                            },
                          },
                        },
                        [_vm._v("\n          Add Phone\n        ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Addresses" } },
                    [_c("address-settings")],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Emails" } },
                    [
                      _c("ct-table", {
                        attrs: {
                          loaded: _vm.loaded,
                          items: _vm.emails,
                          fields: _vm.emailFields,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(line1)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.item.kind) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(actions)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.edit-email-modal",
                                          modifiers: {
                                            "edit-email-modal": true,
                                          },
                                        },
                                      ],
                                      staticClass: "mr-2",
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit("email", data.item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Edit\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-danger",
                                      attrs: {
                                        disabled: _vm.disableDelete(data.item),
                                        variant: "link",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteEmail(data.item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Delete\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1944754446
                        ),
                      }),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.add-email-modal",
                              modifiers: { "add-email-modal": true },
                            },
                          ],
                          class: _vm.$mq !== "sm" ? "mr-2 rounded" : "",
                          attrs: { variant: "primary", size: "$mq" },
                          on: {
                            click: function ($event) {
                              return _vm.create("email")
                            },
                          },
                        },
                        [_vm._v("\n          Add Email\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("phone-modal", {
        attrs: { bus: _vm.bus },
        on: {
          complete: function ($event) {
            return _vm.complete("Thank you for updating your phone number.")
          },
        },
      }),
      _c("email-modal", {
        attrs: { bus: _vm.bus },
        on: {
          complete: function ($event) {
            return _vm.complete("Thank you for updating your email.")
          },
        },
      }),
      _c("general-info-modal", {
        attrs: { bus: _vm.bus },
        on: {
          complete: function ($event) {
            return _vm.complete("Thank you for updating your name.")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }