<template>
  <b-container>
    <masthead title="Account Settings" :show-search="false" />
    <div v-if="loaded" class="container-fluid pt-4 ml-0 pl-0 pr-0">
      <b-tabs v-model="tabIndex">
        <b-tab :title="'General'">
          <ct-table :loaded="loaded" :items="[account]" :fields="ownerFields">
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-general-info-modal
                variant="link"
                class="mr-2"
                @click="edit('generalInfo', data.item)"
              >
                Edit
              </b-button>
            </template>
          </ct-table>
        </b-tab>

        <b-tab :title="'Phones'">
          <ct-table :loaded="loaded" :items="phones" :fields="phoneFields">
            <template slot="cell(phoneNumber)" slot-scope="data">
              {{ data.item.phone_number }}
            </template>
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-phone-modal
                variant="link"
                class="mr-2"
                @click="edit('phone', data.item)"
              >
                Edit
              </b-button>
              <b-button
                v-if="phones.length > 1"
                class="text-danger"
                variant="link"
                @click="deletePhone(data.item)"
              >
                Delete
              </b-button>
            </template>
          </ct-table>
          <b-button
            v-b-modal.add-phone-modal
            variant="primary"
            size="$mq"
            :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
            @click="create('phone')"
          >
            Add Phone
          </b-button>
        </b-tab>

        <b-tab :title="'Addresses'">
          <address-settings />
        </b-tab>

        <b-tab :title="'Emails'">
          <ct-table :loaded="loaded" :items="emails" :fields="emailFields">
            <template slot="cell(line1)" slot-scope="data">
              {{ data.item.email_address }}
            </template>
            <template slot="cell(line1)" slot-scope="data">
              {{ data.item.kind }}
            </template>
            <template slot="cell(actions)" slot-scope="data">
              <b-button
                v-b-modal.edit-email-modal
                variant="link"
                class="mr-2"
                @click="edit('email', data.item)"
              >
                Edit
              </b-button>
              <b-button
                :disabled="disableDelete(data.item)"
                class="text-danger"
                variant="link"
                @click="deleteEmail(data.item)"
              >
                Delete
              </b-button>
            </template>
          </ct-table>

          <b-button
            v-b-modal.add-email-modal
            variant="primary"
            size="$mq"
            :class="$mq !== 'sm' ? 'mr-2 rounded' : ''"
            @click="create('email')"
          >
            Add Email
          </b-button>
        </b-tab>
      </b-tabs>
    </div>

    <phone-modal :bus="bus" @complete="complete('Thank you for updating your phone number.')" />
    <email-modal :bus="bus" @complete="complete('Thank you for updating your email.')" />
    <general-info-modal :bus="bus" @complete="complete('Thank you for updating your name.')" />
  </b-container>
</template>

<script>
import GeneralInfoModal from '../components/AccountSettings/GeneralInfoModal'
import AddressSettings from '@/components/AccountSettings/AddressSettings'
import EmailModal from '../components/AccountSettings/EmailModal'
import PhoneModal from '../components/AccountSettings/PhoneModal'
import { extend } from 'vee-validate'
import { phoneNumber } from '@/directives/directives'
import { required } from 'vee-validate/dist/rules'
import { mapGetters, mapActions } from 'vuex'
import Masthead from '@/components/shared/Masthead'
import CtTable from '../components/shared/CtTable'

import Vue from 'vue'

extend('required', {
  ...required,
  message: '{_field_} is required',
})

export default {
  name: 'AccountSettings',
  components: {
    CtTable,
    Masthead,
    PhoneModal,
    EmailModal,
    AddressSettings,
    GeneralInfoModal,
  },
  directives: {
    phoneNumber,
  },
  data() {
    return {
      bus: new Vue(),
      selected: {},
      newPhone: {},
      newEmail: {},
      errors: [],
      loaded: null,
      errored: false,
      tabIndex: 0,
      emailFields: [
        { key: 'email_address', sortable: false },
        { key: 'kind', sortable: false },
        { key: 'actions', sortable: false, label: '' },
      ],
      phoneFields: [
        { key: 'phone_number', sortable: false },
        { key: 'actions', sortable: false, label: '' },
      ],
      ownerFields: [
        { key: 'first_name', sortable: false, label: 'First Name' },
        { key: 'last_name', sortable: false, label: 'Last Name' },
        { key: 'actions', sortable: false, label: '' },
      ],
    }
  },
  computed: {
    ...mapGetters('account', [
        'account',
        'emails',
        'phones',
        'primaryEmailOnlyOne',
      ]
    ),
  },
  async mounted() {
    this.loaded = false
    await this.loadAccount()
    this.loaded = true
  },
  methods: {
    ...mapActions('account', [
        'loadAccount',
        'deletePersonPhone',
        'deletePersonEmail',
      ],
    ),
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    view(person_object) {
      this.$router.push({
        name: 'accountSettingEditor',
        params: { personId: person_object.id },
      })
    },
    complete(message) {
      this.makeToast('primary', 'Success!', message)
    },
    async edit(name, info) {
      this.bus.$emit(name, Object.assign({}, { ...info }), 'edit')
    },
    async create(name) {
      this.bus.$emit(name)
    },
    async deletePhone(phone) {
      this.$bvModal.hide('destroyPhone')
      try {
        await this.deletePersonPhone({ phone_id: phone.id })
        this.makeToast('primary', 'Success', 'Deleted!')
      } catch {
        this.makeToast('danger', 'Error', 'Sorry this phone can not be deleted.')
      }
    },
    async deleteEmail(email) {
      this.$bvModal.hide('destroyEmail')
      try {
        await this.deletePersonEmail({ email_id: email.id })
        this.makeToast('primary', 'Success', 'Email Deleted!')
      } catch(error) {
        this.makeToast('danger', 'Error', error.response.data.error.message)
      }
    },
    disableDelete(email) {
      return this.emails.length <= 1 || ((email.kind === 'primary') && this.primaryEmailOnlyOne)
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 0;
  margin-bottom: 0;
}
h4.card-title {
  font-size: 105%;
}
.remove-form-person-btn {
  margin-top: -2em;
}
@media only screen and (max-width: 550px) {
}
#account-container {
  padding-top: 1rem !important;
}
.alert-icon {
  stroke: red;
}
</style>
